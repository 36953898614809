<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-img
                :src="getFilePath()"
                height="140px"
                max-height="140px"
                id="img"
                :contain="contain"
                aspect-ratio="1"
                @click="clickUpload"
                class="grey lighten-4"
            />
          </v-col>
          <v-col cols="12" md="12" class="d-flex">
            <v-icon size="40" class="mr-4">
              {{ getFileIcon(file_ext) }}
            </v-icon>
            <div style="height: 54px; display: flex; flex: 1.08; font-size: 1rem; align-items: center" class="grey lighten-4 pa-4 mr-2">
              {{ file_name ? file_name : 'Файл ще не обраний' }}
            </div>
            <v-btn depressed height="54px">
              <v-icon>mdi-attachment</v-icon>
            </v-btn>
            <v-file-input
                accept="image/*"
                v-model="file"
                filled
                hide-details
                :id="id"
                @change="changeFile"
                style="flex: 0 0 100px; display: none"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Коментар" v-model="comment"
                          hide-details
                          full-width
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" class="button-accept" :loading="getModalLoading">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {BASE_URL_AXIOS} from '@/utils/axios';
import {CREATE_SITE_GALLERY, REMOVE_SITE_GALLERY, UPDATE_SITE_GALLERY} from "@/store/actions/personalSite/site";
import {ext_obj} from "@/utils/icons";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'gallery_modal_delete'

export default {
  name: "HWP_ModalGallery",
  mixins: [ModalComponentMixin],
  data() {
    return {
      file_ext: this.item.file_ext,
      file_name: this.item.file_name,
      file_path: this.item.file_path,
      comment: this.item.comment,
      file: null,
      menu: false,
      contain: false,
      BASE_URL_AXIOS,
      publicPath: process.env.BASE_URL,
      id: Date.now()
    }
  },
  methods: {
    clickUpload() {
      const btn = document.getElementById(`${this.id}`)
      btn.click()
    },
    getFileIcon(ext) {
      if (!ext) {
        return 'mdi-file-image-outline'
      }
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-file-image-outline'
    },
    changeFile(payload) {
      if (!payload) {
        return
      }

      const file_ext = (ext_obj.find(item_ext => item_ext.mime === payload.type)) || {ext: 'txt', mime: ''}

      this.file_path = payload.name
      this.file_ext = file_ext.ext
      this.file_name = payload.name
    },
    getFilePath() {
      if (this.file) {
        this.contain = true
        return URL.createObjectURL(this.file)
      } else {
        if (this.file_path) {
          this.contain = true
          return `${BASE_URL_AXIOS}/${this.file_path}`
        } else {
          this.contain = true
          return `${this.publicPath}img/icons/no_image.png`
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.file_ext = this.item.file_ext
      this.file_name = this.item.file_name
      this.file_path = this.item.file_path
      this.comment = this.item.comment
      this.file = null
      this.id = Date.now()

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення новини ${this.theme}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = true

      this.$refs.form.validate()
      if (!this.formValid || !this.file_name) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Оберіть будь ласка файл...',
          color: 'error lighten-1'
        })
        return
      }

      const formData = new FormData()
      formData.append('comment', this.comment)
      if (this.file) {
        formData.append('files', this.file)
      }

      // const img = document.getElementById('img').getElementsByClassName('v-image__image')
      // const src = img[0].style.backgroundImage

      if (this.isNew) {
        this.$store.dispatch(CREATE_SITE_GALLERY, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        formData.append('id_', this.itemId)
        this.$store.dispatch(UPDATE_SITE_GALLERY, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.file_ext = payload.file_ext
              this.file_path = payload.file_path
              this.file_name = payload.file_name
              this.comment = payload.comment
              this.file = null
              this.id = Date.now()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_SITE_GALLERY, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
